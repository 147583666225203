import React from 'react';

import MapShape2 from '../../assets/images/shape/map-shape2.png'
import Partner1 from '../../assets/images/partner/partner1.png'
import Partner2 from '../../assets/images/partner/partner2.png'
import Partner3 from '../../assets/images/partner/partner3.png'
import Partner4 from '../../assets/images/partner/partner4.png'
import Partner5 from '../../assets/images/partner/partner5.png'
import Partner6 from '../../assets/images/partner/partner6.png'
import Partner7 from '../../assets/images/partner/partner7.png'
import Partner8 from '../../assets/images/partner/partner8.png'
import Partner9 from '../../assets/images/partner/partner9.png'
import Partner10 from '../../assets/images/partner/partner10.png'
import Partner11 from '../../assets/images/partner/partner11.png'
import Partner12 from '../../assets/images/partner/partner12.png'

const TheGroup = () => {
    return (
        <section className="why-choose-us-area bg-color">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="why-choose-us-content">
                            <h2>The Group</h2>
                            <p>
                                Cobranet Limited is part of the Boulos Group of Nigeria which has been engaged in
                                Manufacture/Trading/and the Service Business in Nigeria since the 1950s.
                                The group is known for its commitment in providing high quality goods and services in the Nigerian Market.
                            </p>
                            <p>
                                The group is also involved in the manufacture of motorcycles (Suzuki), high quality tissue
                                products (Rose Brand of Products), Distribution of IT products which is done through
                                Compuleb Ltd (Acer/Seagate/Samsung amongst other brands) and Internet Services.
                            </p>
                            <div className="map-shape2">
                                <img src={MapShape2} alt="MapShape" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="our-brand-partners-list">
                            <h2>Our Partners <br/>& Preferred Vendors</h2>
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-sm-6 col-md-6">
                                    <img src={Partner1} alt="Partner" />
                                </div>

                                <div className="col-lg-6 col-sm-6 col-md-6">
                                    <img src={Partner2} alt="Partner" />
                                </div>

                                <div className="col-lg-6 col-sm-6 col-md-6">
                                    <img src={Partner3} alt="Partner" />
                                </div>

                                <div className="col-lg-6 col-sm-6 col-md-6">
                                    <img src={Partner4} alt="Partner" />
                                </div>

                                <div className="col-lg-6 col-sm-6 col-md-6">
                                    <img src={Partner5} alt="Partner" />
                                </div>

                                <div className="col-lg-6 col-sm-6 col-md-6">    
                                    <img src={Partner6} alt="Partner" />
                                </div>

                                <div className="col-lg-6 col-sm-6 col-md-6">    
                                    <img src={Partner7} alt="Partner" />
                                </div>

                                <div className="col-lg-6 col-sm-6 col-md-6">    
                                    <img src={Partner8} alt="Partner" />
                                </div>

                                <div className="col-lg-6 col-sm-6 col-md-6">    
                                    <img src={Partner9} alt="Partner" />
                                </div>

                                <div className="col-lg-6 col-sm-6 col-md-6">    
                                    <img src={Partner10} alt="Partner" />
                                </div>

                                <div className="col-lg-6 col-sm-6 col-md-6">    
                                    <img src={Partner11} alt="Partner" />
                                </div>

                                <div className="col-lg-6 col-sm-6 col-md-6">    
                                    <img src={Partner12} alt="Partner" />
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TheGroup;