import React from 'react'
import { Link } from 'gatsby'
import aboutImage from '../../assets/images/about/about-img9.jpg'
import starIcon from '../../assets/images/star-icon.png'

const AboutUsContent = () => {
    return (
        <section className="case-study-area bg-fffbf5">
            <div className="container-fluid">
            <div className="single-case-study-item ptb-100">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-12">
                                <div className="case-study-content">
                                    <span className="sub-title">
                                        <img src={starIcon} alt="banner" /> 
                                        About Us
                                    </span>
                                    <h2>Internet Service and Data Provider Leader</h2>
                                    <p>
                                Cobranet Limited is a leading internet service and data provider. 
                                Since our  launch in 2003, we have proudly provided various industries 
                                and sectors nationally with reliable and secure Internet Services and Data Management solutions.
                                </p>
                                <p>
                                Cobranet’s promise is to continue to provide innovative and world class solutions 
                                at the best feature-to price and service –to –price ratio to ensure that customers 
                                achieve their individual and business targets while enjoying the peace of mind that comes with a stable internet.
                                </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12">
                                <div className="case-study-image">
                                    <Link to="/case-studies-details" className="d-block">
                                        <img src={aboutImage} alt="banner" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutUsContent;