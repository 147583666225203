import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import mission from '../../assets/images/about/about-mission.jpg'
import objectives from '../../assets/images/about/about-objectives.jpg'
import values from '../../assets/images/about/about-values.jpg'

const Details = () => {
    return (
        <section className="history-area ptb-100 bg-fafafb">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="banner" /> 
                        Our Story
                    </span>
                    <h2>Our Story Started in 2003</h2>
                </div>

                <ol className="timeline history-timeline">
                    <li className="timeline-block">

                        <div className="timeline-icon">
                            <span className="dot-badge"></span>
                        </div>

                        <div className="timeline-content">
                            <div className="row align-items-center">
                                <div className="col-lg-7 col-md-12">
                                    <div className="content">
                                        <h3>Our Mission</h3>
                                        <p>
                                        To be a service provider of choice in Internet services and other related fields 
                                        with the aim of expanding to achieve national coverage.
                                        </p>
                                        <p>
                                        Cobranet aims to leverage its edge over competition in products and services, 
                                        improve productivity and cater to the welfare of all its clients. In the near future, 
                                        we aim to set the pace for 21st Century internet Service Providers.
                                        </p>
                                        <p>
                                        Our slogan simply is “Always on the Go”
                                        </p>
                                    </div>
                                </div>

                                <div className="col-lg-5 col-md-12">
                                    <div className="image">
                                        <img src={mission} alt="banner" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li className="timeline-block">

                        <div className="timeline-icon">
                            <span className="dot-badge"></span>
                        </div>

                        <div className="timeline-content">
                            <div className="row align-items-center">
                                <div className="col-lg-7 col-md-12">
                                <div className="about-content">
                                    <div className="content">
                                        <h3>Objectives</h3>
                                        <ul className="content-list">
                                    <li><i className="flaticon-tick"></i> Ensure the provision of uninterrupted fast, secure and reliable internet connection</li>
                                    <li><i className="flaticon-tick"></i> Implement the best industry practices to help clients achieve high return on investment </li>
                                    <li><i className="flaticon-tick"></i> Become a top stakeholder in the IT industry</li>
                                    <li><i className="flaticon-tick"></i> Provide unparalleled internet services on a national scale</li>
                                    <li><i className="flaticon-tick"></i> Expand services within Nigeria as a first phase and West Africa for the future</li>
                                    <li><i className="flaticon-tick"></i> Acquire 50% of Market Share in Nigeria</li>
                                </ul>
                                    </div>
                                    </div>
                                </div>

                                <div className="col-lg-5 col-md-12">
                                    <div className="image">
                                        <img src={objectives} alt="banner" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li className="timeline-block">

                        <div className="timeline-icon">
                            <span className="dot-badge"></span>
                        </div>

                        <div className="timeline-content">
                            <div className="row align-items-center">
                                <div className="col-lg-7 col-md-12">
                                    <div className="content">
                                        <h3>Our Value</h3>
                                        <p>
                                        We serve Residential and Corporate markets and deliver fast, secure and reliable services under all circumstances.
                                        </p>
                                        <p>
                                        Our client’s benefits from our advanced broadband and fibre technology. 
                                        We offer superior client support and a client-centric culture that sets us apart from the competition. 
                                        Our 24/7 call centre operates with a standby mobile engineering team in various locations 
                                        within Lagos and our other locations.
                                        </p>
                                    </div>
                                </div>

                                <div className="col-lg-5 col-md-12">
                                    <div className="image">
                                        <img src={values} alt="banner" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ol>
            </div>
        </section>
    )
}

export default Details